import * as React from 'react';
import {Typography, Container, Grid, Paper} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Footer from '../components/Footer/Footer';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import {Link, useParams } from 'react-router-dom'



import axios from 'axios';

const client = axios.create({
  baseURL: "http://185.231.59.13:4000" 
});
const round = (num) => {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return Math.round(m) / 100 * Math.sign(num);
}

const ProjectEdit = () => {
  const [projectDets, setProjectDets] = React.useState(null);
  const { id } = useParams();
  React.useEffect(() => {
    async function getProjetcs() {
      const url = "/projects/"+id
      const response = await client.get(url);
      setProjectDets(response.data);
    }
    getProjetcs();
  }, []);
    return(
<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
<Grid container spacing={3}>
  <Grid item xs={11}>
    <Typography variant="h4" component="h2">
    {projectDets ? projectDets.name : "..."}</Typography>
  </Grid>
  <Grid item xs={1}>
    <IconButton to="/projects/" component={Link} aria-label="edit" color="primary">
        <ArrowBackIcon />
    </IconButton>
  </Grid>
  <Grid item xs={12} >
  <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Project Name</TableCell>
            <TableCell align="right">Site Name</TableCell>
            <TableCell align="right">Rigs</TableCell>
            <TableCell align="right">Drillers</TableCell>
            <TableCell align="right">Drill Bits</TableCell>
            <TableCell align="right">Borholes</TableCell>
            <TableCell align="right">Total depth</TableCell>
            <TableCell align="right">Total drilled</TableCell>
            <TableCell align="right">Progress</TableCell>
            <TableCell align="right">Operation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
         projectDets ?
         <TableRow>
         <TableCell>
           {projectDets.name}
         </TableCell>
         <TableCell>
           {projectDets.site_name}
         </TableCell>
         <TableCell>
           {projectDets.rig_count}
         </TableCell>
         <TableCell>
           {projectDets.driller_count}
         </TableCell>
         <TableCell>
           {projectDets.bit_count}
         </TableCell>
         <TableCell>
           {projectDets.bh_count}
         </TableCell>
         <TableCell>
           {projectDets.total_depth}
         </TableCell>
         <TableCell>
           {projectDets.total_drilled}
         </TableCell>
         <TableCell>
           {projectDets.total_drilled / projectDets.total_depth }
         </TableCell>
       </TableRow>
           : <TableRow>
               <TableCell colSpan={10}>
                 <LinearProgress />
               </TableCell>
             </TableRow>
            }
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>
  {/* Recent Deposits */}
  <Grid item xs={12} lg={6}>
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 240,
      }}
    >
      test
    </Paper>
  </Grid>
  {/* Recent Orders */}
  <Grid item xs={12} lg={6}>
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      Area 3
    </Paper>
  </Grid>
</Grid>
<Footer />
</Container>
    )
}

export default ProjectEdit;