import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
//import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import BadgeIcon from '@mui/icons-material/Badge';
import { Link } from "react-router-dom";

function ListItemLink(props) {
  const { icon, primary, to, index , setIndex, selectedIndex} = props;
  const handleListItemClick = (event, index) => {
    setIndex(index);
  };
  return (
      <ListItem button selected={selectedIndex === index} onClick={(event) => handleListItemClick(event, index)} component={Link} to={to}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
  );
}

const mainList = [
  {
    primary: "Dashboard",
    to: "/",
    icon: <DashboardIcon/>,
    index: 0
  },
  {
    primary:"Projects",
    to:"/projects",
    icon: <BadgeIcon />,
    index: 1
  },
  {
    primary:"Rigs",
    to:"/rigs",
    icon: <BarChartIcon />,
    index: 2
  },
  {
    primary:"Drillers",
    to:"/drillers",
    icon: <PeopleIcon />,
    index: 3
  },
  {
    primary:"Bits",
    to:"/bits",
    icon: <LayersIcon />,
    index: 4
  },
]

export const MainListItems = (props) =>{
  return(
    <>
    {mainList.map((item)=>{
      return(
        <ListItemLink selectedIndex={props.selectedIndex} setIndex={props.setIndex} key={item.primary} to={item.to} icon={item.icon} primary={item.primary} index={item.index}/>
      )
    })}
  </>
  )
};