import Layout from './components/Layout'
import Dashboard from './pages/Dashboard'
import Rigs from './pages/Rigs'
import Bits from './pages/Bits'
import Drillers from './pages/Drillers'
import Projects from './pages/Projects'
import ProjectEdit from './pages/ProjectEdit'

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="projects" element={<Projects />} />
          <Route path="projects/:id" element={<ProjectEdit />} />
          <Route path="rigs" element={<Rigs />} />
          <Route path="drillers" element={<Drillers />} />
          <Route path="bits" element={<Bits />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default App;
