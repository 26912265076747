import * as React from 'react';
import {Typography, Container, Grid, Paper} from '@mui/material';
import Footer from '../components/Footer/Footer';

const Rigs = () =>{
return(
<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
<Grid container spacing={3}>
<Grid item xs={12}>
    <Typography variant="h4">Rigs</Typography>
  </Grid>
  {/* Chart */}
  <Grid item xs={12} md={8} lg={9}>
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 240,
      }}
    >
      test 1
    </Paper>
  </Grid>
  {/* Recent Deposits */}
  <Grid item xs={12} md={4} lg={3}>
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 240,
      }}
    >
      test 2
    </Paper>
  </Grid>
  {/* Recent Orders */}
  <Grid item xs={12}>
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      test 3
    </Paper>
  </Grid>
</Grid>
<Footer />
</Container>
)
    }
export default Rigs