import * as React from 'react';
import { Link , Typography } from '@mui/material';

const Copyright = (props) => {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://bit-performance.com/">
          Bit Performance
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const Footer = (props) => {
    return(
        <Copyright sx={{ pt: 4 }} />
    )
}

export default Footer;