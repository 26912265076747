import * as React from 'react';
import {Typography, Container, Grid, Paper} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Footer from '../components/Footer/Footer';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import {Link} from 'react-router-dom'
import ReactEcharts from "echarts-for-react"; 

import axios from 'axios';

const client = axios.create({
  baseURL: "http://185.231.59.13:4000" 
});
const round = (num) => {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return Math.round(m) / 100 * Math.sign(num);
}

const Projects = () =>{
  
  const option = {
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [120, 200, 150, 80, 70, 110, 130],
        type: 'bar'
      }
    ]
  }; 

  const [projectList, setProjectList] = React.useState(null);
  React.useEffect(() => {
    async function getProjetcs() {
      const url = "/projects/"
      const response = await client.get(url);
      setProjectList(response.data);
    }
    getProjetcs();
  }, []);

return(
<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
<Grid container spacing={3}>
<Grid item xs={12}>
    <Typography variant="h4" component="h2">Projects</Typography>
  </Grid>
  <Grid item xs={12} >
  <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Project Name</TableCell>
            <TableCell align="right">Site Name</TableCell>
            <TableCell align="right">Rigs</TableCell>
            <TableCell align="right">Drillerst</TableCell>
            <TableCell align="right">Drill Bits</TableCell>
            <TableCell align="right">Borholes</TableCell>
            <TableCell align="right">Total depth</TableCell>
            <TableCell align="right">Total drilled</TableCell>
            <TableCell align="right">Progress</TableCell>
            <TableCell align="right">Operation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
        projectList ?
        projectList.map((row) => (
            <TableRow
              hover
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.site_name}</TableCell>
              <TableCell align="right">{row.rig_count}</TableCell>
              <TableCell align="right">{row.driller_count}</TableCell>
              <TableCell align="right">{row.bit_count}</TableCell>
              <TableCell align="right">{row.bh_count}</TableCell>
              <TableCell align="right">{row.total_depth ? row.total_depth : 0}</TableCell>
              <TableCell align="right">{row.total_drilled ? row.total_drilled : 0}</TableCell>
              <TableCell align="right">{row.total_depth && row.total_drilled ? round(row.total_drilled/row.total_depth*100): 0 }%</TableCell>
              <TableCell align="right">
                <IconButton aria-label="delete" color="error">
                  <DeleteIcon />
                </IconButton>
                <IconButton to={"/projects/"+row.id} component={Link} aria-label="edit" color="primary">
                  <EditIcon />
                </IconButton>
              </TableCell> 
            </TableRow>
          )) : <TableRow>
               <TableCell colSpan={12}>
                 <LinearProgress />
               </TableCell>
             </TableRow>
            }
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>
  {/* Recent Deposits */}
  <Grid item xs={12} lg={6}>
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
      }}
    >
      <h3>E chart</h3>
      <ReactEcharts option={option} />
    </Paper>
  </Grid>
  {/* Recent Orders */}
  <Grid item xs={12} lg={6}>
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      Area 3
    </Paper>
  </Grid>
</Grid>
<Footer />
</Container>
)
    }
export default Projects